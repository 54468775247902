.hero {
    height: 1080px;
    align-items: center;
    display: flex;
    justify-content: center;
    /* background-image: url('../../assets/graphics/grid.svg'), linear-gradient(#04020e 70%, #171041);
    background-size: cover, auto;
    background-position: center, center; 
    background-repeat: no-repeat, no-repeat;  */
    /* background-image: linear-gradient(#04020e 70%, #171041); */
    /* background-image: linear-gradient(#171041 70%, #331b67); */
    background-color: #04020e;
    padding-top: 80px;
    /* padding-bottom: 5rem; */
    width: 100%;
    overflow: hidden;
}

.hero-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    max-width: 1400px;
    padding-bottom: 15rem;
}

.hero-button {
    display: flex;
    width: 300px;
    justify-content: center;
    margin-left: 300px;
}

.hero-icons {
    display: flex;
    justify-content: center;
    height: 25px;
    gap: 25px;
    padding-top: 5px;
}

.social-icon {
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: black;
}

.social-icon:hover {
    transform: scale(1.15);
}

.hero-main {
    margin-top: 145px;
    margin-left: 300px;
    margin-right: 255px;
    justify-content: right;
    min-width: 400px;
}

.hero-title {
    text-align: right;
    font-size: 2.7em;
    /* color: white; */
  font-weight: bold;
  background: -webkit-linear-gradient(45deg, #171041, #171041);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-transform: uppercase;
  text-shadow:
    -5px -5px 0 #eee,
    -8px -8px 0 #171041;
    font-family: "Press Start 2P", sans-serif;
}

.hero-subTitle {
    text-align: right;
    margin-top: 5px;
    font-size: 1.5em;
    margin-left: 95px;
    color: #fff;
    /* text-shadow: white 0px 0px 12px; */
    font-family: "Press Start 2P", sans-serif;
}

.hero-info {
    width: 50%;             /* Set width to 50% of the parent div */
    text-align: right;       /* Keep text aligned to the right within the 50% */
    margin-top: 5px;
    font-size: 01em;
    margin-left: auto;       /* Remove left margin */
    margin-right: 0;         /* Optional: add right margin if needed */
    color: #fcfcfc;
    /* text-shadow: white 0px 0px 12px; */
    font-family: "Press Start 2P", sans-serif;
}


.hero-button {
    margin-left: auto;
    margin-right: -65px;
}


@media screen and (max-width: 750px) {
    .hero {
        height: 650px;
    }

    .hero-main {
        width: 390px;
        margin: 0 auto;
        margin-top: 85px;
        min-width: 200px;
    }

    .hero-title {
        font-size: 2.25em;
        text-align: center;
    }

    .hero-subTitle {
        font-size: 1.1em;
        margin-top: 5px;
        margin-left: 0;
        text-align: center;
    }

    .hero-button {
        margin-right: -55px;
        /* margin: 0 auto; */
    }
    
}

@media screen and (min-width: 1200px) {
    .hero-main {
        /* width: 390px; */
        margin-top: 145px;
        margin-right: 325px;
    }
}



@media screen and (min-width: 751px) and (max-width: 900px) {
    /* .hero {
        height: 900px;
    }

    .hero-main {
        margin-left: 200px;
        margin-top: 125px;
    }

    .hero-title {
        font-size: 30px;
    }

    .hero-subTitle {
        font-size: 22px;
        margin-top: 5px;
    } */
}

.section-title {
    font-family: "Press Start 2P", sans-serif;
    /* text-shadow: white 0px 0px 20px; */
    margin-left: 2rem;
    font-size: 60px;
    /* font-weight: 400; */
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@media screen and (max-width: 600px) {
    .section-title {
        margin-left: 0;
        font-size: 45px;
    }
}


@media screen and (min-width: 601px) and (max-width: 1200px) {
    .section-title {
        margin-left: 0;
        font-size: 55px;
    }
}
